























































































import { Component, Vue } from "vue-property-decorator";
import { JSEncrypt } from "jsencrypt";
import { LoginForm } from "@/models/LoginForm";
import { EncryptedForm } from "@/models/EncryptedForm";
import { PEM_REQUEST, AUTH_REQUEST } from "@/store/actions/auth";
import jwt_decode from "jwt-decode";
import parseJSON from "date-fns/parseJSON/index";

@Component({})
export default class Login extends Vue {
  private loginForm: LoginForm = new LoginForm("", "");
  private pem: string = "";
  private errorText: string = "";
  private errorExplain: string = "";
  private errorCode: string = "";
  private valid: boolean = false;
  private show: boolean = false;
  private error_alert = false;
  private loadingBtn = false;

  private toString(str) {
    return str as string;
  }
  private logIn() {
    this.loadingBtn = true;
    let formJSON = JSON.stringify(this.loginForm);
    console.log("JSON: ", formJSON);

    var rsa = new JSEncrypt({});
    rsa.setPublicKey(this.pem);
    var encrypted = rsa.encrypt(formJSON);
    let encryptedObj = new EncryptedForm(encrypted as string);
    console.log("encrypted JSON:", encryptedObj);

    this.$store
      .dispatch(AUTH_REQUEST, encryptedObj)
      .then(() => {
        let status = this.$store.getters.AUTH_STATUS;
        let token = this.toString(localStorage.getItem("access_token"));
        let decoded = jwt_decode(token) as any;
        console.log("decoded", decoded);
        // console.log("role", decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]);
        // console.log("region_id", decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality"]);
        // console.log("name", decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]);
        localStorage.setItem(
          "role",
          decoded[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ]
        );

        localStorage.setItem(
          "region_id",
          decoded[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality"
          ]
        );

        localStorage.setItem(
          "name",
          decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]
        );

        localStorage.setItem(
          "user_id",
          decoded[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid"
          ]
        );
        var exp = +decoded.exp;
        console.log("Expiration:", exp);
        localStorage.setItem("exp", this.toString(exp));
        this.$router.push({ name: "Pages" });
      })
      .catch(() => {
        this.errorText = "Не удалось выполнить вход.";
        let errorExplain = "";
        let errorCode = this.$store.getters.AUTH_ERROR;
        this.error_alert = true;
        switch (errorCode) {
          case 0:
            errorExplain = "Нет соединения";
            break;
          case 401:
            errorExplain = "Неверный Логин/Пароль";
            break;
          case 403:
            errorExplain = "Отказано в доступе";
            break;
          default:
            errorExplain = "Неизвестная ошибка";
            break;
        }
        this.errorCode = "Код ошибки: " + errorCode + " (" + errorExplain + ")";
      })
      .finally(() => {
        this.loadingBtn = false;
      });
  }
  private mounted() {
    this.$store.dispatch(PEM_REQUEST).then(() => {
      this.pem = this.$store.getters.PEM;
      console.log("pem: ", this.pem);
    });
  }
}
