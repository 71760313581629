export class LoginForm{
  login: string;
  password: string;
  constructor(login?: string, password?: string) {
    login = "";
    password = "";
    this.login = login;
    this.password = password;
  }
}
